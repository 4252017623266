import React, { useRef, useEffect, useState } from "react"
import styles from "@/styles/signup-popup.module.scss"
import Form from "./Form.es"
import formStyles from "@/styles/popup-form.module.scss"
import successImg from "@/images/signup-success_spanish.svg"
import Sizing from "./Sizing"
import Share from "./Share"
import Close from "./Close"

const SignupPopup = ({ color }) => {
    const [success, setSuccess] = useState(false)
    const [hide, setHide] = useState(false)
    const [open, setOpen] = useState(false)
    const [fade, setFade] = useState(false)
    const [hasCookie, setHasCookie] = useState(false)
    const trigger = useRef()

    const setCookie = () => {
        const now = new Date()
        let time = now.getTime()
        time += 60 * 60 * 1000
        now.setTime(time)
        document.cookie = `popup=true; expires=${now.toUTCString()}; path=/`
    }

    const triggerSuccess = () => {
        setSuccess(true)
        setHasCookie(true)
        setTimeout(() => {
            setHide(true)
        }, 1000)
    }

    const closeFn = () => {
        setCookie()
        setHasCookie(true)

        setFade(true)
        setTimeout(() => {
            setOpen(false)
        }, 1000)
    }

    const getCookie = name => {
        const nameEQ = name + "="
        const ca = document.cookie.split(";")
        for (let i = 0; i < ca.length; i++) {
            const c = ca[i]
            while (c.charAt(0) == " ") c = c.substring(1, c.length)
            if (c.indexOf(nameEQ) == 0)
                return c.substring(nameEQ.length, c.length)
        }
        return null
    }

    useEffect(() => {
        const cookie = getCookie("popup")

        if (cookie || hasCookie) return

        const scrollFn = () => {
            if (open) return
            const { top } = trigger.current.getBoundingClientRect()
            const { innerHeight } = window

            if (top < innerHeight) setOpen(true)
        }
        window.addEventListener("scroll", scrollFn)

        const click = e => {
            // console.log(e.target)

            if (e.target.classList.contains("closeclick")) closeFn()
        }
        window.addEventListener("click", click)

        return () => {
            window.removeEventListener("scroll", scrollFn)
            window.removeEventListener("click", click)
        }
    }, [hasCookie])

    return (
        <div
            className={
                styles.main +
                " " +
                (open ? styles.open : "") +
                " " +
                (fade ? styles.fade : "")
            }
        >
            <div ref={trigger}></div>

            <div className={styles.fixed + " closeclick"}>
                <Sizing
                    // styles={{ float: "left" }}

                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 20,
                                marginLeft: 1,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                width: 16,
                                marginLeft: 4,
                                // marginBottom: 1,
                            },
                        },
                        {
                            min: 1200,
                            val: {
                                width: 14,
                                marginLeft: 5,
                                // marginBottom: 1,
                            },
                        },

                        {
                            min: 1600,
                            val: {
                                width: 10,
                                marginLeft: 7,
                                // marginBottom: 1,
                            },
                        },
                        // { min: 1200, val: { marginLeft: 8, width: 10 } },
                    ]}
                >
                    <div className={styles.bottom}>
                        <div
                            className={styles.middle}
                            // style={{ "--color": color }}
                        >
                            <div className={styles.top}>
                                <div
                                    className={`${styles.content}  ${
                                        success ? styles.fadeout : ""
                                    } ${hide ? styles.hide : ""}`}
                                >
                                    <span className={styles.heading + " h4"}>
                                        Regístrese para recibir alertas y actualizaciones de Free Markets Destroy. 
                                    </span>
                                    <span className={styles.copy}>
                                        ¡No te pierdas ni un minuto de la acción del libre mercado y únete a otros en todo el país!
                                    </span>

                                    <Form
                                        styles={formStyles}
                                        triggerSuccess={triggerSuccess}
                                    ></Form>
                                </div>

                                <div
                                    className={`${styles.success} ${
                                        success ? styles.show : ""
                                    } ${hide ? styles.fadein : ""}`}
                                >
                                    <img src={successImg} alt="" />
                                    <span className={styles.heading + " h4"}>
                                        Gracias por suscribirte a Free Markets Destroy.
                                    </span>
                                    <span className={styles.copy}>
                                        Pronto tendrá noticias nuestras.
                                    </span>
                                </div>

                                <div className={styles.closeWrap}>
                                    <Close onClick={closeFn}></Close>
                                </div>
                            </div>
                        </div>
                    </div>
                </Sizing>
            </div>
        </div>
    )
}

export default SignupPopup
