import React, { useRef, useEffect, useState } from "react"
import styles from "../../styles/signup.module.scss"
import Form from "./Form.es"
import formStyles from "../../styles/form.module.scss"
import successImg from "../../images/signup-success_spanish.svg"
import Sizing from "./Sizing"
import Share from "./Share.es"

const Signup = ({ color }) => {
    const [success, setSuccess] = useState(false)
    const [hide, setHide] = useState(false)

    const colors = [].concat(color)

    if (colors.length == 1) {
        colors.unshift("#262626")
    }

    const triggerSuccess = () => {
        setSuccess(true)

        setTimeout(() => {
            setHide(true)
        }, 1000)
    }

    return (
        <>
            <div className={styles.main}>
                <Share type="signup" />

                <div className={styles.bottom} style={{ "--color": colors[0] }}>
                    <div
                        className={styles.middle}
                        style={{ "--color": colors[1] }}
                    >
                        <div className={styles.top}>
                            <div
                                className={`${styles.content}  ${
                                    success ? styles.fadeout : ""
                                } ${hide ? styles.hide : ""}`}
                            >
                                <span className={styles.heading + " h4"}>
                                    Regístrese para recibir alertas y actualizaciones de Free Markets Destroy.
                                </span>
                                <span className={styles.copy}>
                                    Reciba más artículos de interés, información de eventos, actualizaciones de noticias y comunicaciones, simplemente complete el campo a continuación para suscribirse.
                                </span>

                                <Form
                                    styles={formStyles}
                                    triggerSuccess={triggerSuccess}
                                ></Form>
                            </div>

                            <div
                                className={`${styles.success} ${
                                    success ? styles.show : ""
                                } ${hide ? styles.fadein : ""}`}
                            >
                                <img src={successImg} alt="" />
                                <span className={styles.heading + " h4"}>
                                    Gracias por suscribirte a Free Markets Destroy.
                                </span>
                                <span className={styles.copy}>
                                    Pronto tendrá noticias nuestras.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Signup
