import React from "react"
import { Link } from "gatsby"
import Image from "@/components/general/Image"
// import Layout from "@/components/layout"
// import Image from "@/components/image"
import SEO from "@/components/seo"
import Opener from "@/components/general/Opener"
import Sizing from "@/components/general/Sizing"
import Parallax from "@/components/general/Parallax"
import Heading from "@/components/general/Heading"
import DiseaseGraph from "@/components/graphs/DiseaseGraph.es"
import ColorChangeBG from "@/components/general/ColorChangeBG"
import Spacing from "@/components/general/Spacing"
import Signup from "@/components/general/Signup.es"
import Dropcap from "@/components/general/Dropcap"
import FooterNav from "@/components/general/FooterNav"
import pullquote from "@/images/rents/pullquote.svg"
import angle from "@/images/angle.svg"
import Motif from "@/components/general/Motif"
import Footer from "@/components/general/Footer.es"
import Share from "@/components/general/Share.es"

import openerText from "@/images/disease/opener-text_spanish.png"
import openerTextSml from "@/images/disease/opener-text-sml_spanish.png"
import shareImg from "@/images/og/og_disease_spanish.jpg"

import dce from "@/images/disease/dce_spanish.png"
import dcg from "@/images/disease/dcg_spanish.png"

import SignupPopup from "@/components/general/SignupPopup.es"

const DiseasePage = ({pageContext}) => {
    return (
        <>
            <SEO
                page="/es/disease"
                title="Free Markets Destroy |  Aprenda cómo los mercados libres destruyen la enfermedad"
                description="Incluso los críticos de los mercados suelen conceder que la competencia y la innovación mejoran nuestras vidas. Pero, ¿realmente los mercados libres nos hacen más saludables?"
                img={shareImg}
                lang="es"
            />

            <Opener
                pageName="disease"
                titles={[
                    { img: openerText, size: 14 },
                    { img: openerTextSml, size: 22 },
                ]}
            />

            {
                // ======================================= SECTION 1 =======================================
            }

            <Sizing
                styles={{ float: "right" }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            marginRight: 2,
                            width: 20,
                            marginBottom: 1,
                        },
                    },
                    {
                        min: 1024,
                        val: {
                            marginRight: 2,
                            width: 10,
                            // marginBottom: 1,
                        },
                    },
                    // { min: 1200, val: { marginLeft: 8, width: 10 } },
                ]}
            >
                <div className="copy--lead">
                    <Dropcap img={dce}>I</Dropcap>ncluso los críticos de los mercados suelen admitir que la competencia y la innovación mejoran nuestras vidas. Los mercados libres sacan a las personas de la pobreza al proporcionar incentivos, generalmente ingresos, beneficios y capital social, para el trabajo duro y el servicio. Pero como lo atestiguarán millones de milenios adictos al trabajo que luchan contra el agotamiento, estar bien no es lo mismo que estar bien. Claro, los mercados libres nos dieron pelotón y pizza keto, pero ¿realmente nos hacen más saludables?

                </div>
            </Sizing>

            <Sizing
                styles={{ float: "left" }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            marginLeft: 2,
                            width: 20,
                            marginBottom: 1,
                        },
                    },
                    {
                        min: 1024,
                        val: {
                            marginLeft: 2,
                            width: 14,
                            // marginBottom: 1,
                        },
                    },
                    // { min: 1200, val: { marginLeft: 8, width: 10 } },
                ]}
            >
                <Image
                    src={"disease_advance"}
                    style={{ width: "100%" }}
                ></Image>
            </Sizing>

            <div style={{ float: "left", clear: "left" }}>
                <SignupPopup></SignupPopup>
            </div>

            <Sizing
                styles={{ float: "left", clear: "left" }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            marginLeft: 2,
                            width: 6,
                            marginTop: -2,
                        },
                    },
                    {
                        min: 1024,
                        val: {
                            marginLeft: 2,
                            width: 4,
                            marginTop: -2,
                        },
                    },
                    // { min: 1200, val: { marginLeft: 8, width: 10 } },
                ]}
            >
                <span className="caption">
                    - En el siglo XIX, esto fue un ejercicio
                </span>
            </Sizing>
            <Sizing
                styles={{ float: "right", position: "relative", zIndex: 4 }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            marginRight: 2,
                            width: 20,
                            marginBottom: 1,
                        },
                    },
                    {
                        min: 1024,
                        val: {
                            marginRight: 2,
                            width: 10,
                            marginTop: 1,
                            marginBottom: 2,
                        },
                    },
                    {
                        min: 1200,
                        val: {
                            marginRight: 4,
                            width: 8,
                            marginTop: 1,
                            marginBottom: 2,
                        },
                    },
                    // { min: 1200, val: { marginLeft: 8, width: 10 } },
                ]}
            >
                <p className="copy">
                    La respuesta es inequívocamente sí. Los mercados libres ya han ayudado a la humanidad a conquistar algunos de nuestros desafíos de salud y bienestar más desalentadores, conectando a miles de millones de personas con alimentos alcanzables, agua potable limpia y equipos médicos que salvan vidas. Nos proporcionaron horas de tiempo libre diario para descansar, hacer ejercicio y socializar. Desde la publicación de La riqueza de las naciones de Adam Smith en 1776 y el surgimiento del capitalismo de libre mercado en Europa y América, la esperanza de vida global ha pasado de 29 años a más de 70.
                </p>

                <p className="copy">
                    Tómese un momento para dejar que eso se digiera. En 250 años, un parpadeo en el gran esquema de la historia humana, la innovación, la competencia y la elección habilitadas por los mercados libres nos dieron 40 años adicionales de vida. Eso es un montón de velas adicionales en un pastel de cumpleaños (vegano, sin azúcar).
                </p>

                <Sizing
                    styles={{ position: "absolute" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                bottom: -2,
                                right: -2,
                                width: 2,
                            },
                        },
                    ]}
                >
                    <Motif page="disease" id="motif_8" movement={-0.1} />
                </Sizing>
            </Sizing>

            <Sizing
                styles={{ float: "left", position: "relative", zIndex: 4 }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            marginLeft: 2,
                            marginTop: 2,
                            width: 3,
                        },
                    },
                ]}
            >
                <Motif page="disease" id="motif_9" movement={0.2} />
            </Sizing>

            <DiseaseGraph></DiseaseGraph>

            <Sizing
                styles={{ float: "right" }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            marginRight: 2,
                            width: 20,
                            marginBottom: 1,
                        },
                    },
                    {
                        min: 1024,
                        val: {
                            marginRight: 2,
                            width: 10,
                            marginTop: 0,
                        },
                    },
                    {
                        min: 1200,
                        val: {
                            marginRight: 4,
                            width: 8,
                            marginTop: 0,
                        },
                    },
                    // { min: 1200, val: { marginLeft: 8, width: 10 } },
                ]}
            >
                <p className="copy--lead">
                    Por supuesto, los mercados libres no son más que unas cuantas reglas y protecciones básicas que nos ayudan a servirnos unos a otros. Como le gustaba decir al defensor del libre mercado Leonard E. Read, un mercado es simplemente "cualquier cosa que sea pacífica". Los verdaderos héroes de cualquier sistema de libre mercado son los individuos y equipos que dedican sus vidas a resolver problemas. Individuos como el Dr. Maurice Hilleman.{" "}
                </p>
                <p className="copy">
                    A menudo llamado el padre de las vacunas modernas, Hilleman pasó la mayor parte de su carrera trabajando en Bristol-Myers Squibb y Merck, dos de las compañías farmacéuticas más influyentes, que salvan vidas en el mundo. Para cuando se retiró a la edad de 65 años, Hilleman había prevenido la gripe pandémica, creado la vacuna combinada contra el sarampión, las paperas y la rubéola (MMR) y desarrollado la primera vacuna del mundo contra el cáncer. Hoy, más de 30 años después de retirarse de Merck, el trabajo de Hilleman continúa salvando un estimado de ocho millones de vidas cada año, principalmente a través de la vacuna MMR que todavía es propiedad y producida por su antiguo empleador, Merck.{" "}
                </p>

                <p className="copy">
                    El Dr. Hilleman fue un científico brillante, pero también fue un rompedor de reglas iconoclasta. En 1963, cuando su hija Jeryl Lynn contrajo los síntomas clásicos de las paperas, Hilleman se frotó la parte posterior de la garganta y usó la muestra del virus para crear el primer prototipo de vacuna contra las paperas. Hasta el día de hoy, la cepa viral utilizada para producir la vacuna MMR se llama cepa Jeryl Lynn. Muchos de los admiradores del Dr. Hilleman, incluido el Dr. Anthony Fauci, han comentado que es poco probable que el estilo idiosincrásico de Hilleman produzca resultados tan sorprendentes en el estricto entorno regulatorio actual.{" "}
                </p>
            </Sizing>

            <Sizing
                styles={{ float: "left" }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            marginLeft: 1,
                            width: 22,
                            marginTop: -0.8,
                        },
                    },
                    {
                        min: 1224,
                        val: {
                            marginLeft: 1,
                            width: 10,
                            marginTop: 2,
                            paddingBottom: 0.3,
                        },
                    },
                    // { min: 1200, val: { marginLeft: 8, width: 10 } },
                ]}
            >
                <Image src="disease_illu2" style={{ width: "100%" }}></Image>
            </Sizing>

            <Sizing
                styles={{ float: "left", position: "relative" }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            marginLeft: 2,
                            width: 15,
                            marginTop: -0.8,
                            marginBottom: 3,
                        },
                    },
                    {
                        min: 1224,
                        val: {
                            marginLeft: 1,
                            width: 8,
                            marginTop: -0.8,
                            marginBottom: 0,
                        },
                    },
                    // { min: 1200, val: { marginLeft: 8, width: 10 } },
                ]}
            >
                <span className="caption">
                    - El Dr. Maurice Hilleman vacunando a su hija en los sesentas.
                </span>

                <Sizing
                    styles={{ position: "absolute" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                left: 4,
                                bottom: -4,
                                width: 1.5,
                            },
                        },
                    ]}
                >
                    <Motif page="disease" id="motif_1" movement={-0.1} />
                </Sizing>
            </Sizing>

            <ColorChangeBG
                from={{ r: 54, g: 153, b: 221 }}
                to={{ r: 242, g: 174, b: 187 }}
            >
                <div
                    style={{
                        width: "100%",
                        paddingBottom: "10%",
                        backgroundImage: `url(${angle})`,
                        backgroundSize: "100% 100%",
                    }}
                ></div>

                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginTop: 1,
                                marginLeft: 2,
                                width: 20,
                                marginBottom: 1,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 3,
                                width: 8,
                                marginTop: 1,
                                marginBottom: 0,
                            },
                        },
                        {
                            min: 1200,
                            val: {
                                marginLeft: 5,
                                width: 8,
                                marginTop: 1,
                                marginBottom: 0,
                            },
                        },
                        // { min: 1200, val: { marginLeft: 8, width: 10 } },
                    ]}
                >
                    <p className="copy">
                        Impaciente y emprendedor, el Dr. Hilleman creía que las instituciones académicas y las agencias gubernamentales carecían de la tenacidad para llevar las innovaciones científicas al mercado, donde harían el mayor bien. Es por eso que pasó su carrera trabajando para grandes corporaciones con bolsillos profundos y un serio compromiso con la innovación. Hilleman sostuvo que los científicos tenían la responsabilidad de proporcionar un retorno financiero sobre el conocimiento adquirido en el laboratorio para que compañías como Merck continuaran financiando costosos proyectos de investigación con el potencial de transformar el mundo.
                    </p>

                    <p className="copy">
                        Y si nunca has oído hablar de las paperas, bueno, tienes que agradecer al Dr. Hilleman y a los mercados libres.
                    </p>

                    <p className="copy">
                        Una nota final: además de corporaciones como Squibb y Merck, los mercados libres también nos brindan organizaciones benéficas masivas como la Fundación Bill y Melinda Gates y el Instituto Médico Howard Hughes. Estas organizaciones, fundadas por capitalistas famosos, están haciendo tanto como cualquier compañía privada o agencia gubernamental para ayudar a la humanidad a vencer la enfermedad. Y son, en el sentido más puro, productos del libre mercado, concebidos y lanzados por empresarios que buscan resolver los problemas que aquejan a la humanidad.
                    </p>
                </Sizing>

                <Sizing
                    styles={{ float: "right" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginRight: 4,
                                width: 18,
                                marginBottom: 3,
                                marginTop: 4,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginRight: 2,
                                width: 4,
                                marginTop: 2,

                                minWidth: "350px",
                            },
                        },
                        // { min: 1200, val: { marginLeft: 8, width: 10 } },
                    ]}
                >
                    <Signup color={"#e99245"}></Signup>
                </Sizing>

                <Sizing
                    styles={{ float: "right" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 0,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginTop: 1,
                                marginRight: 4,
                                width: 2,
                            },
                        },
                    ]}
                >
                    <Motif page="disease" id="motif_3" movement={-0.1} />
                </Sizing>
                <Sizing
                    styles={{ float: "right", position: "relative" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginRight: 2,
                                width: 20,
                                // marginBottom: 1,
                                paddingBottom: 0.3,
                                marginTop: 1,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginRight: 4,
                                width: 16,
                                marginTop: 2,
                                paddingBottom: 0.3,
                            },
                        },
                        // { min: 1200, val: { marginLeft: 8, width: 10 } },
                    ]}
                >
                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    left: -3,
                                    top: -2,
                                    width: 2,
                                },
                            },
                        ]}
                    >
                        <Motif page="disease" id="motif_2" movement={0.1} />
                    </Sizing>

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    right: -3,
                                    bottom: 0,
                                    width: 1.5,
                                },
                            },
                        ]}
                    >
                        <Motif
                            page="disease"
                            id="motif_10"
                            movement={0.1}
                            rotation={-0.1}
                        />
                    </Sizing>

                    <Image src="disease_lab" style={{ width: "100%" }}></Image>
                </Sizing>
                <Sizing
                    styles={{ float: "right" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginRight: 2,
                                width: 20,
                                marginBottom: 1,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginRight: 4,
                                width: 16,
                                marginBottom: 0,
                            },
                        },
                        // { min: 1200, val: { marginLeft: 8, width: 10 } },
                    ]}
                >
                    <span className="caption">
                        - Miles de investigadores están trabajando día y noche para descubrir la próxima gran vacuna.
                    </span>
                </Sizing>

                <Sizing
                    styles={{ float: "left", position: "relative" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 2,
                                width: 20,
                                marginTop: 3,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 4,
                                width: 12,
                                marginTop: 4,
                            },
                        },
                        // { min: 1200, val: { marginLeft: 8, width: 10 } },
                    ]}
                >
                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    left: 4,
                                    top: -3,
                                    width: 2,
                                },
                            },
                        ]}
                    >
                        <Motif page="disease" id="motif_9" movement={0.1} />
                    </Sizing>

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    right: -6,
                                    top: -1,
                                    width: 2.5,
                                },
                            },
                        ]}
                    >
                        <Motif page="disease" id="cover_07" movement={-0.1} />
                    </Sizing>

                    <Heading navy="true" langKey={pageContext.langKey}>23andMe, A TI Y A TODOS</Heading>
                </Sizing>

                <Sizing
                    styles={{ float: "right" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginRight: 2,
                                width: 20,
                                marginTop: 2,
                                marginBottom: 2,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginRight: 3,
                                width: 9,
                                marginTop: 1,
                            },
                        },
                        // { min: 1200, val: { marginLeft: 8, width: 10 } },
                    ]}
                >
                    <div className={"copy--lead"}>
                        <Dropcap img={dcg}>L</Dropcap>
                        a compañía de pruebas genéticas 23andMe hace más que rastrear su ascendencia. La compañía proporciona a los clientes información vital sobre su predisposición a enfermedades cardíacas, cáncer, diabetes y otras enfermedades no transmisibles.
                    </div>
                </Sizing>

                <Sizing
                    styles={{ float: "left", position: "relative" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 2,
                                width: 20,
                                marginTop: 1,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 2,
                                width: 14,
                                marginTop: 1,
                            },
                        },
                        // { min: 1200, val: { marginLeft: 8, width: 10 } },
                    ]}
                >
                    <Image
                        src="disease_illu1"
                        style={{ width: "100%" }}
                    ></Image>

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    right: -2,
                                    top: 3,
                                    width: 0.8,
                                },
                            },
                        ]}
                    >
                        <Motif
                            page="disease"
                            id="motif_4"
                            movement={-0.1}
                            rotation={0.1}
                        />
                    </Sizing>

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    right: -7,
                                    top: 3,
                                    width: 0.8,
                                },
                            },
                        ]}
                    >
                        <Motif
                            page="disease"
                            id="motif_5"
                            movement={-0.1}
                            rotation={-0.1}
                        />
                    </Sizing>

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    right: -2.5,
                                    bottom: 3,
                                    width: 0.9,
                                },
                            },
                        ]}
                    >
                        <Motif
                            page="disease"
                            id="motif_7"
                            movement={-0.2}
                            rotation={-0.2}
                        />
                    </Sizing>
                </Sizing>

                <Sizing
                    styles={{ float: "right", position: "relative" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginRight: 2,
                                width: 20,
                                marginTop: 1,
                                marginBottom: 2,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginRight: 3,
                                width: 9,
                                marginTop: 1,
                                marginBottom: 2,
                            },
                        },
                        // { min: 1200, val: { marginLeft: 8, width: 10 } },
                    ]}
                >
                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    left: -2,
                                    top: 3,
                                    width: 0.8,
                                },
                            },
                        ]}
                    >
                        <Motif
                            page="disease"
                            id="motif_5"
                            movement={-0.1}
                            rotation={0.1}
                        />
                    </Sizing>

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    left: -7,
                                    top: 3,
                                    width: 0.9,
                                },
                            },
                        ]}
                    >
                        <Motif
                            page="disease"
                            id="motif_7"
                            movement={-0.1}
                            rotation={-0.1}
                        />
                    </Sizing>

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    left: -4,
                                    top: 5,
                                    width: 0.8,
                                },
                            },
                        ]}
                    >
                        <Motif
                            page="disease"
                            id="motif_4"
                            movement={-0.2}
                            rotation={-0.2}
                        />
                    </Sizing>

                    <p className="copy">
                        Por ejemplo, una prueba de 23andMe puede decirte si tienes un marcador genético que indica un riesgo más alto que el promedio de cáncer de mama. Esta información ayuda a los clientes de 23andMe a tomar decisiones más inteligentes para proteger su salud, ya sea que eso signifique beber menos, modificar su dieta o tomar medicina preventiva.
                    </p>
                    <p className="copy">
                        La compañía también ofrece oportunidades para que los clientes participen en ensayos clínicos para nuevas intervenciones que salvan vidas. Esto acelera el ritmo del descubrimiento científico al tiempo que ayuda a las personas a tomar el control de sus propios viajes de salud y bienestar. Con más de 7 786 millones en fondos en los últimos años, 23andMe sigue creciendo, explorando lo que es posible en un mercado médico en rápida evolución. Y su historia es solo un ejemplo más de cómo los mercados impulsan a los innovadores que producirán el próximo gran avance científico.
                    </p>
                </Sizing>
                <Spacing space="0" />
                <Sizing
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: "auto",
                                marginRight: "auto",
                                width: 20,
                                maxWidth: "300px",
                                height: "50px",
                                marginTop: 2,
                                paddingBottom: 5,
                                position: "relative",
                                display: "block",
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                display: "none",
                            },
                        },
                    ]}
                >
                    <Share></Share>
                </Sizing>
            </ColorChangeBG>
            <Spacing space="0" />
            <FooterNav next={"hunger"} previous={"racism"} langKey={pageContext.langKey}/>
            <Footer></Footer>
        </>
    )
}

export default DiseasePage
